import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Switcher from "../utils/header/LanguageSwitcher";
import "../../styles/components/blocks/Menu.scss";
import gsap from "gsap";
import eventBus from "../../utils/eventBus";
import imagesLoaded from "imagesloaded";

// Import images dor each element of the menu
import AboutImageDesk from "../../images/menu/desktop/image_about.jpg";
import AccueilImageDesk from "../../images/menu/desktop/image_acceuil.jpg";
import ContactImageDesk from "../../images/menu/desktop/image_contact.jpg";
import ActivitiesImageDesk from "../../images/menu/desktop/image_activities.jpg";
import LesSuitesImageDesk from "../../images/menu/desktop/image_les_suites.jpg";
import RestaurationImageDesk from ".././../images/menu/desktop/image_restauration.jpg";

// Create a fullscreen menu
const Menu = ({ path }) => {
  // if path contains /en all links will be in english
  // else all links will be in french
  const [bgImageUrl, setBgImageUrl] = useState();
  const lang = path.includes("/en") ? "/en" : "";
  const [hovered, setHovered] = useState(false);
  const imageChangeHover = (e) => {
    // if a link or li is hovered the image will change for the equivalent index of picture html tag
    // ex: if the index is 0, the image will change to the first picture
    const index = e.target.getAttribute("index");
    const picture = document.querySelector(
      ".bi_fullscreen_menu_gallery picture:nth-child(" + index + ")"
    );
    gsap.killTweensOf(picture);
    gsap.killTweensOf(picture.querySelector("img"));
    picture.style.display = "block";
    gsap.fromTo(
      picture,
      { opacity: 1, x: "100vw", zIndex: 10, visibility: "initial" },
      {
        opacity: 1,
        x: 0,
        visibility: "initial",
        zIndex: 10,
        ease: "power3.out",
        duration: 1,
      }
    );
    gsap.fromTo(
      picture.querySelector("img"),
      { x: "-100vw" },
      { x: 0, ease: "power3.out", duration: 1 }
    );
  };

  const hideImageLeaveHover = (e) => {
    // if a link or li is hovered the image will change for the equivalent index of picture html tag
    // ex: if the index is 0, the image will change to the first picture
    const index = e.target.getAttribute("index");
    const picture = document.querySelector(
      ".bi_fullscreen_menu_gallery picture:nth-child(" + index + ")"
    );

    gsap.killTweensOf(picture);
    gsap.killTweensOf(picture.querySelector("img"));
    gsap.fromTo(
      picture,
      { opacity: 1, x: 0, visibility: "initial", zIndex: 8 },
      {
        opacity: 1,
        x: "-100vw",
        zIndex: 8,
        visibility: "initial",
        duration: 2,
        delay: 0.5,
      }
    );
    gsap.fromTo(
      picture.querySelector("img"),
      { x: 0 },
      { x: "100vw", duration: 2, delay: 0.5 }
    );
  };

  const currentBackgroundImage = () => {
    let imageUrl;
    switch (path) {
      case "/":
        imageUrl = AccueilImageDesk;
        break;
      case "/activities":
        imageUrl = ActivitiesImageDesk;
        break;
      case "/restauration":
        imageUrl = RestaurationImageDesk;
        break;
      case "/les_suites":
        imageUrl = LesSuitesImageDesk;
        break;
      case "/contact":
        imageUrl = ContactImageDesk;
        break;
      case "/a_propos":
        imageUrl = AboutImageDesk;
        break;
      case "/activities/":
        imageUrl = ActivitiesImageDesk;
        break;
      case "/restauration/":
        imageUrl = RestaurationImageDesk;
        break;
      case "/les_suites/":
        imageUrl = LesSuitesImageDesk;
        break;
      case "/contact/":
        imageUrl = ContactImageDesk;
        break;
      case "/a_propos/":
        imageUrl = AboutImageDesk;
        break;
      case "/en":
        imageUrl = AccueilImageDesk;
        break;
      case "/en/activities":
        imageUrl = ActivitiesImageDesk;
        break;
      case "/en/restauration":
        imageUrl = RestaurationImageDesk;
        break;
      case "/en/les_suites":
        imageUrl = LesSuitesImageDesk;
        break;
      case "/en/contact":
        imageUrl = ContactImageDesk;
        break;
      case "/en/a_propos":
        imageUrl = AboutImageDesk;
        break;
      case "/en/":
        imageUrl = AccueilImageDesk;
        break;
      case "/en/activities/":
        imageUrl = ActivitiesImageDesk;
        break;
      case "/en/restauration/":
        imageUrl = RestaurationImageDesk;
        break;
      case "/en/les_suites/":
        imageUrl = LesSuitesImageDesk;
        break;
      case "/en/contact/":
        imageUrl = ContactImageDesk;
        break;
      case "/en/a_propos/":
        imageUrl = AboutImageDesk;
        break;
      default:
        imageUrl = AccueilImageDesk;
    }
    setBgImageUrl(imageUrl);
  };

  const onMouseOverFunc = () => {
    setHovered(true);
  };

  useEffect(() => {
    currentBackgroundImage();
    let tl = gsap.timeline({
      onReverseComplete: () => {
        eventBus.dispatch("pageAppear");
      },
    });
    tl.fromTo(
      ".bi_fullscreen_menu",
      { opacity: 0, height: 0 },
      { opacity: 1, height: "100vh" }
    );
    tl.fromTo(
      ".bi_fullscreen_menu_content li",
      { opacity: 0, y: -10 },
      { opacity: 1, stagger: 0.1, duration: 0.3, y: 0, delay: "-=0.2" }
    );
    tl.fromTo(
      ".bi_fullscreen_menu_gallery",
      { opacity: 0 },
      { opacity: 1, duration: 0.3 },
      "<"
    );

    eventBus.on("closeMenuAnimation", () => {
      tl.reverse();
    });
    eventBus.on("RouteUpdate", () => {
      imagesLoaded("main", () => {
        tl.reverse();
      });
    });
  }, []);

  return (
    <div className="bi_fullscreen_menu">
      <div className="bi_fullscreen_menu_content">
        <ul>
          <li>
            <Link
              to={lang + "/"}
              index="1"
              onMouseEnter={(e) => imageChangeHover(e)}
              onMouseLeave={(e) => hideImageLeaveHover(e)}
              onMouseMove={(e) => onMouseOverFunc(e)}
            >
              {lang === "/en" ? "Home" : "Accueil"}
            </Link>
          </li>
          <li>
            <Link
              to={lang + "/a_propos"}
              index="2"
              onMouseEnter={(e) => imageChangeHover(e)}
              onMouseLeave={(e) => hideImageLeaveHover(e)}
              onMouseMove={(e) => onMouseOverFunc(e)}
            >
              {" "}
              {lang === "/en" ? "About" : "À propos"}
            </Link>
          </li>
          <li>
            <Link
              to={lang + "/les_suites"}
              index="3"
              onMouseEnter={(e) => imageChangeHover(e)}
              onMouseLeave={(e) => hideImageLeaveHover(e)}
              onMouseMove={(e) => onMouseOverFunc(e)}
            >
              {lang === "/en" ? "The suites" : "Les Suites"}
            </Link>
          </li>
          <li>
            <Link
              to={lang + "/restauration"}
              index="4"
              onMouseEnter={(e) => imageChangeHover(e)}
              onMouseLeave={(e) => hideImageLeaveHover(e)}
              onMouseMove={(e) => onMouseOverFunc(e)}
            >
              {lang === "/en" ? "Restauration" : "Restauration"}
            </Link>
          </li>
          <li>
            <Link
              to={lang + "/activities"}
              index="5"
              onMouseEnter={(e) => imageChangeHover(e)}
              onMouseLeave={(e) => hideImageLeaveHover(e)}
              onMouseMove={(e) => onMouseOverFunc(e)}
            >
              {lang === "/en" ? "Activities" : "Activités"}
            </Link>
          </li>
          <li>
            <Link
              to={lang + "/contact"}
              index="6"
              onMouseEnter={(e) => imageChangeHover(e)}
              onMouseLeave={(e) => hideImageLeaveHover(e)}
              onMouseMove={(e) => onMouseOverFunc(e)}
            >
              {lang === "/en" ? "Contact" : "Contact"}
            </Link>
          </li>
        </ul>
        <ul className="bi_social_link">
          <li>
            <a
              href="https://www.facebook.com/lessuitesdelilebarbe"
              target="_blank"
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="circle"
                  cx="18"
                  cy="18"
                  r="17.5"
                  fill="#D7D7D7"
                />
                <circle cx="18" cy="18" r="17.5" stroke="#D7D7D7" />
                <path
                  d="M24.0001 10.6665H12.0001C11.2634 10.6665 10.6667 11.2632 10.6667 11.9998V23.9998C10.6667 24.7365 11.2634 25.3332 12.0001 25.3332H18.6667V19.3332H16.6667V17.3332H18.6667V16.2592C18.6667 14.2258 19.6574 13.3332 21.3474 13.3332C22.1567 13.3332 22.5847 13.3932 22.7874 13.4205V15.3332H21.6347C20.9174 15.3332 20.6667 15.7118 20.6667 16.4785V17.3332H22.7694L22.4841 19.3332H20.6667V25.3332H24.0001C24.7367 25.3332 25.3334 24.7365 25.3334 23.9998V11.9998C25.3334 11.2632 24.7361 10.6665 24.0001 10.6665Z"
                  fill="#F8F4F2"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/lessuitesdelilebarbe/"
              target="_blank"
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="18"
                  cy="18"
                  r="18"
                  fill="#D7D7D7"
                  className="circle"
                />
                <circle cx="18" cy="18" r="18" stroke="#D7D7D7" />
                <path
                  d="M20.8005 10.1333H11.2005C10.6111 10.1333 10.1338 10.6106 10.1338 11.2V20.8C10.1338 21.3893 10.6111 21.8666 11.2005 21.8666H20.8005C21.3898 21.8666 21.8671 21.3893 21.8671 20.8V11.2C21.8671 10.6106 21.3898 10.1333 20.8005 10.1333ZM13.8426 19.7333H12.2693V14.6709H13.8426V19.7333ZM13.0399 13.9472C12.5327 13.9472 12.1226 13.536 12.1226 13.0298C12.1226 12.5237 12.5333 12.113 13.0399 12.113C13.5455 12.113 13.9567 12.5242 13.9567 13.0298C13.9567 13.536 13.5455 13.9472 13.0399 13.9472ZM19.7359 19.7333H18.1637V17.2714C18.1637 16.6842 18.153 15.929 17.3461 15.929C16.5274 15.929 16.4015 16.5685 16.4015 17.2288V19.7333H14.8293V14.6709H16.3386V15.3626H16.3599C16.5701 14.9648 17.0831 14.545 17.8485 14.545C19.4415 14.545 19.7359 15.5936 19.7359 16.9568V19.7333V19.7333Z"
                  fill="#F8F4F2"
                  style={{transform: "translate(-4px, -5px) scale(1.4)"}}
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/lessuitesdelilebarbe/"
              target="_blank"
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="circle"
                  cx="18"
                  cy="18"
                  r="17.5"
                  fill="#D7D7D7"
                />
                <circle cx="18" cy="18" r="17.5" stroke="#D7D7D7" />
                <path
                  d="M14.6667 10.5C12.3692 10.5 10.5 12.3692 10.5 14.6667V21.3333C10.5 23.6308 12.3692 25.5 14.6667 25.5H21.3333C23.6308 25.5 25.5 23.6308 25.5 21.3333V14.6667C25.5 12.3692 23.6308 10.5 21.3333 10.5H14.6667ZM14.6667 12.1667H21.3333C22.7117 12.1667 23.8333 13.2883 23.8333 14.6667V21.3333C23.8333 22.7117 22.7117 23.8333 21.3333 23.8333H14.6667C13.2883 23.8333 12.1667 22.7117 12.1667 21.3333V14.6667C12.1667 13.2883 13.2883 12.1667 14.6667 12.1667ZM22.1667 13C21.9457 13 21.7337 13.0878 21.5774 13.2441C21.4211 13.4004 21.3333 13.6123 21.3333 13.8333C21.3333 14.0543 21.4211 14.2663 21.5774 14.4226C21.7337 14.5789 21.9457 14.6667 22.1667 14.6667C22.3877 14.6667 22.5996 14.5789 22.7559 14.4226C22.9122 14.2663 23 14.0543 23 13.8333C23 13.6123 22.9122 13.4004 22.7559 13.2441C22.5996 13.0878 22.3877 13 22.1667 13ZM18 13.8333C15.7025 13.8333 13.8333 15.7025 13.8333 18C13.8333 20.2975 15.7025 22.1667 18 22.1667C20.2975 22.1667 22.1667 20.2975 22.1667 18C22.1667 15.7025 20.2975 13.8333 18 13.8333ZM18 15.5C19.3783 15.5 20.5 16.6217 20.5 18C20.5 19.3783 19.3783 20.5 18 20.5C16.6217 20.5 15.5 19.3783 15.5 18C15.5 16.6217 16.6217 15.5 18 15.5Z"
                  fill="#F8F4F2"
                />
              </svg>
            </a>
          </li>
          <li>
            <a href="">
              <Switcher path={path} />
            </a>
          </li>
        </ul>
      </div>
      <div
        className="bi_fullscreen_menu_gallery"
        style={{
          backgroundOrigin: "content-box",
          backgroundSize: "cover",
          backgroundImage:
            "url(" +
            bgImageUrl +
            ")",
          color: "red",
        }}
      >
        <picture>
          <img src={AccueilImageDesk} alt="" sizes="" srcset="" />
        </picture>
        <picture>
          <img src={AboutImageDesk} alt="" sizes="" srcset="" />
        </picture>
        <picture>
          <img src={LesSuitesImageDesk} alt="" sizes="" srcset="" />
        </picture>
        <picture>
          <img src={RestaurationImageDesk} alt="" sizes="" srcset="" />
        </picture>
        <picture>
          <img src={ActivitiesImageDesk} alt="" sizes="" srcset="" />
        </picture>
        <picture>
          <img src={ContactImageDesk} alt="" sizes="" srcset="" />
        </picture>
      </div>
    </div>
  );
};

export default Menu;
