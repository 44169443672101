import gsap from "gsap/all";
import React from "react";
import Switcher from "../header/LanguageSwitcher";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

const Actions = ({ path, scroll }) => {
  const scrollToTop = (el) => {
    el.preventDefault();
    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(window, {
      duration: 1,
      scrollTo: 0,
      onComplete: () => {
        window.scrollTo(0, 0);
        if(scroll){
          scroll.scrollTo(0);
        }
      },
    });
  };

  return (
    <div className="bi_actions_wrapper">
      <button onClick={(e) => scrollToTop(e)}>
        <svg
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="29" cy="29" r="28.5" stroke="#D7D7D7" />
          <circle className="circle" cx="29" cy="29" r="28.5" fill="#D7D7D7" />
          <path
            d="M30.1667 39L30.1667 23.2556L34.355 27.2333L36 25.6667L29 19L22 25.6667L23.645 27.2333L27.8333 23.2556L27.8333 39L30.1667 39Z"
            fill="#F8F4F2"
          />
        </svg>
      </button>
      <Switcher path={path} />
    </div>
  );
};

export default Actions;
