import React from "react";
import { Link } from "gatsby";

const ListLink = ({path, lang}) => {
  return (
    <ul className='listLink'>
      <li>
        <Link to={(lang === 'en' ? lang : '') + '/a_propos'}>{lang === "en" ? "About" : "À propos"}</Link>
      </li>
      <li>
        <Link to={(lang === 'en' ? lang : '') + '/les_suites'}>{lang === "en" ? "The suites" : "Les Suites"}</Link>
      </li>
      <li>
        <Link to={(lang === 'en' ? lang : '') + '/restauration'}>{lang === "en" ? "Restauration" : "Restauration"}</Link>
      </li>
      <li>
        <Link to={(lang === 'en' ? lang : '') + '/activities'}>{lang === "en" ? "Activities" : "Activités"}</Link>
      </li>
      <li>
        <Link to={(lang === 'en' ? lang : '') + '/contact'}>{lang === "en" ? "Contact" : "Contact"}</Link>
      </li>
    </ul>
  );
};

export default ListLink;
