import React, { useEffect, useRef } from "react";
import ActionButton from "../utils/ActionButton";
import './../../styles/components/blocks/fullscreenBooking.scss'

const FullScreenBooking = ({ id }) => {
  const container = useRef(null);

  useEffect(() => {
    // Load the script
    // vérifier si script déja chargé
    const script = document.createElement("script");
    if(!document.querySelector('iframe[name="sirvoy-engine"')){
        script.setAttribute("data-form-id", id);
        script.setAttribute(
          "data-lang",
          window.location.pathname.includes("/en") ? "en" : "fr"
        );
        script.src = `https://secured.sirvoy.com/widget/sirvoy.js`;
        script.async = true;
        container.current.appendChild(script);
    }

    return () => {
     container.current.removeChild(script); 
    };
  }, [window.location.pathname, id]); 

  return (
    <div className="bi_booking">
      <div className="bi_booking_close">
        <ActionButton
          type="external"
          color="black"
          to="https://secured.sirvoy.com/widget/sirvoy.js"
        >
          {window.location.pathname.includes('/en') ? 'Close': 'Fermer'}
        </ActionButton>
      </div>
      <div ref={container} className='bi_booking_wrapper'></div>
    </div>
  );
};

export default FullScreenBooking;
