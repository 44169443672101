import React, { useContext } from "react";
import "../../styles/components/utils/NavButton.scss";
import eventBus from "../../utils/eventBus";
import { LayoutContext } from "../layout";
// Create a button with multiple type of button
// Button with types nav can be open or close if state is true or false
// Button with types gallery can be next or previous if state is true or false
const NavButton = ({ type, state, setState, disabled }) => {
  const closeMenuAnimation = (setMenuOpen) => {
    eventBus.dispatch("closeMenuAnimation");
    eventBus.on('closeMenu', ()=>{
      setMenuOpen(false);
    })
  }
  if (type === "nav") {
    return (
      <LayoutContext.Consumer>
        {({ menuOpen, setMenuOpen }) => {
          if (menuOpen === false) {
            return (
              <button
                className="bi_nav_button bi_nav_button_open"
                onClick={() => setMenuOpen(true)}
              >
                <span className="bi_nav_button_open_line"></span>
                <span className="bi_nav_button_open_line"></span>
                <span className="bi_nav_button_open_line"></span>
              </button>
            );
          } else {
            return (
              <button
                className="bi_nav_button bi_nav_button_close"
                onClick={() => closeMenuAnimation(setMenuOpen)}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.06 9.85355L18.2929 17.0864L17.0864 18.2929L9.85355 11.06L9.5 10.7065L9.14645 11.06L1.91357 18.2929L0.707107 17.0864L7.93998 9.85355L8.29354 9.5L7.93998 9.14645L0.707107 1.91357L1.91357 0.707107L9.14645 7.93998L9.5 8.29354L9.85355 7.93998L17.0864 0.707107L18.2929 1.91357L11.06 9.14645L10.7065 9.5L11.06 9.85355Z"
                    fill="#F8F4F2"
                    stroke="#D7D7D7"
                  />
                </svg>
              </button>
            );
          }
        }}
      </LayoutContext.Consumer>
    );
  } else if (type === "full") {
    if (state === true) {
      return (
        <button
          className="bi_nav_button bi_nav_button_close"
          onClick={() => setState(false)}
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.06 9.85355L18.2929 17.0864L17.0864 18.2929L9.85355 11.06L9.5 10.7065L9.14645 11.06L1.91357 18.2929L0.707107 17.0864L7.93998 9.85355L8.29354 9.5L7.93998 9.14645L0.707107 1.91357L1.91357 0.707107L9.14645 7.93998L9.5 8.29354L9.85355 7.93998L17.0864 0.707107L18.2929 1.91357L11.06 9.14645L10.7065 9.5L11.06 9.85355Z"
              fill="#F8F4F2"
              stroke="#D7D7D7"
            />
          </svg>
        </button>
      );
    } else {
      return (
        <button
          className="bi_nav_button bi_nav_button_fullscreen"
          onClick={() => setState(true)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.85714 12.8571H0V20H7.14286V17.1429H2.85714V12.8571ZM0 7.14286H2.85714V2.85714H7.14286V0H0V7.14286ZM17.1429 17.1429H12.8571V20H20V12.8571H17.1429V17.1429ZM12.8571 0V2.85714H17.1429V7.14286H20V0H12.8571Z"
              fill="#F8F4F2"
            />
          </svg>
        </button>
      );
    }
  } else {
    if (state === true) {
      return (
        <button className="bi_nav_button bi_nav_button_next" onClick={setState} disabled={disabled}>
          <svg
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.09312 3.65972e-07L-3.69798e-07 2.115L6.79892 9L-2.77742e-06 15.885L2.09311 18L11 9L2.09312 3.65972e-07Z"
              fill="#3A3636"
            />
          </svg>
        </button>
      );
    } else if (state === false) {
      return (
        <button className="bi_nav_button bi_nav_button_previous" onClick={setState} disabled={disabled}>
          <svg
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.84531 8.64868L3.49838 9L3.84531 9.35132L10.2969 15.8846L8.90688 17.2892L0.703459 9L8.90688 0.710811L10.2969 2.11538L3.84531 8.64868Z"
              fill="#3A3636"
              stroke="#3A3636"
            />
          </svg>
        </button>
      );
    }
  }
};

export default NavButton;
