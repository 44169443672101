export default (Element, Scroll, gsap) => {
  gsap.ticker.add(Scroll.update);
  Element.defaults({
    scroller: Scroll.containerElement,
  });
  Element.scrollerProxy(Scroll.containerElement, {
    scrollTop(value) {
      return arguments.length ? (Scroll.currentPos = value) : Scroll.currentPos;
    },
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
  });
  Scroll.on("update", Element.update);
  // Verif height page every second
  setInterval(() => {
    Scroll.resize();
  }, 400);
  Element.addEventListener("refresh", Scroll.resize);
};
