import React from "react";
import { graphql, Link } from "gatsby";
import Switcher from "../utils/header/LanguageSwitcher";
import ActionButton from "../utils/ActionButton";
import NavButton from "../utils/NavButton";
import Logo from "../utils/header/Logo";

import "../../styles/components/blocks/Header.scss";
import eventBus from "../../utils/eventBus";

const Header = ({ pageContext, path, menuOpen }) => {
  const [white, setWhite] = React.useState(false);
  const [v404, set404] = React.useState(false);
  React.useEffect(() => {
    eventBus.on("404", () => {
      set404(true);

    });

    eventBus.on("RouteUpdate", ()=>{
      set404(false);
      setWhite(false);
      console.log("leave404")
    })
    
    if(menuOpen) {
      setWhite(true);
    } else if((path === "/a_propos" || path === "/a_propos/" || path === "/en/a_propos" || path === "/en/a_propos/")){
      setWhite(true);
    } else if(v404) {
      setWhite(true);
    } else {
      setWhite(false);
    }

    return function cleanUp() {
      setWhite(false);
    }
  }, [menuOpen, path, v404]);

  React.useEffect(() => {
    return function cleanUp() {
      setWhite(false);
    }
  }, [])

  return (
    <header className="bi_header">
      <div className="bi_header_content">
        <Link to={path.includes("/en") ? "/en" : "/"}>
          <Logo color={white ? "white" : "black"}  />
        </Link>
        <div className="bi_nav_actions">
          {!menuOpen ? (
            <>
              <span className={"bi_nav_item" + ((path === "/a_propos" || path === "/a_propos/" || path === "/en/a_propos" || path === "/en/a_propos/") || v404 ? " white" : "")}>
                <Switcher className="bi_nav_item" path={path} />
              </span>
              <span className="bi_nav_item">
                <ActionButton
                  className="bi_nav_item"
                  color={(path === "/a_propos" || path === "/a_propos/" || path === "/en/a_propos" || path === "/en/a_propos/")  ? "white" : "green"}
                  type="external"
                  to="https://www.google.com/"
                >
                 {path.includes("/en") ? "Book now" : "Réserver"}
                </ActionButton>
              </span>
            </>
          ) : null}

          <span className={(path === "/a_propos" || path === "/a_propos/" || path === "/en/a_propos" || path === "/en/a_propos/") || v404 ? "bi_nav_item white" : "bi_nav_item"}>
            <NavButton
              className={"bi_nav_item" + (path === "/a_propos" || path === "/a_propos/" || path === "/en/a_propos" || path === "/en/a_propos/") ? "white" : ""}
              type="nav"
              state={false}
            ></NavButton>
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
