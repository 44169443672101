import React from "react";
import { graphql } from "gatsby";
import BottomList from "../utils/footer/BottomList";
import Logo from "../utils/footer/Logo";
import InformativeList from "../utils/footer/InformativeList";
import ListLink from '../utils/footer/ListLink';

import '../../styles/components/blocks/footer.scss';
import Actions from "../utils/footer/Actions";

const Footer = ({ data, path, context }) => {
    let lang = path.includes("/en") ? "en" : "fr";
    return (
        <footer>
            <div className="bi_footer_main">
            <Logo/>
            <div>
                <ListLink path={path} lang={lang}/>
                <InformativeList lang={lang}/>
                <Actions path={path} scroll={context ? context.scroll : null} lang={lang}/>
            </div>
            </div>
            <BottomList path={path} lang={lang}/>
        </footer>
    )
}

export default Footer;