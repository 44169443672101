import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Link } from "gatsby";
import "../../styles/components/utils/ActionButton.scss";
import FullScreenBooking from "../blocks/FullScreenBooking";
import gsap from "gsap";
// Button with two color green and black background and internal link and external link capabilities

const ActionButton = ({ color, to, type, children }) => {
  // state for booking is open or not
  const [bookingOpen, setBookingOpen] = useState(false);
  let uniqueId = "a" + Number.parseInt(Math.random() * (20000 - 100) + 100);

  const onLeave = (e) => {
    gsap.to("." + uniqueId + " path", {
      attr: {
        d: "M0,0 C0,0,0.142,0,0.5,0 C0.858,0,1,0,1,0 V1 H0 V0",
      },
    });
    gsap.to("." + uniqueId + " span", {
      display: "inline-block",
      y: "initial",
      duration: 0.3,
    });
  };

  const onEnter = (e) => {
    // get from in the y axis the cursor came
    let y = e.clientY;
    // get the height of the event target
    let height = e.target.clientHeight;

    // si y est superieur à la moitié de la hauteur de l'élément le cursor viens du bas
    let direction = determineDirection(e);

    if (direction !== "down") {
      let tl = gsap.timeline();
      tl.set("." + uniqueId + " path", {
        rotation: 0,
        transformOrigin: "50% 50%",
      });
      tl.to("." + uniqueId + " path", {
        attr: {
          d: "M0,0 C0,0,0.142,0.15,0.5,0.15 C0.858,0.15,1,0,1,0 V1 H0 V0",
        },
      });
      tl.to(
        "." + uniqueId + " span",
        {
          display: "inline-block",
          y: "5px",
          duration: 0.4,
        },
        "<"
      );
    } else {
      let tl = gsap.timeline();
      tl.set("." + uniqueId + " path", {
        rotation: 180,
        transformOrigin: "50% 50%",
      });
      tl.to("." + uniqueId + " path", {
        attr: {
          d: "M0,0 C0,0,0.142,0.15,0.5,0.15 C0.858,0.15,1,0,1,0 V1 H0 V0",
        },
        duration: 0.3,
      });
      tl.to(
        "." + uniqueId + " span",
        {
          display: "inline-block",
          y: "-5px",
          duration: 0.4,
        },
        "<"
      );
    }
  };

  const determineDirection = (e) => {
    let target = e.target;
    let cursorY = e.clientY;
    let targetHeight = target.getBoundingClientRect().height;
    let targetY = target.getBoundingClientRect().top;
    let direction = cursorY < targetY + targetHeight / 2 ? "up" : "down";

    return direction;
  };

  useEffect(() => {
    const Splitting = require("splitting");
    let Splitt = Splitting({
      target: ".action-button [data-splitting]",
    }).forEach((part) => {
      part.chars.forEach((char, index) => {
        char.classList.add('char-' + index);
      })
    });


  }, []);

  if (type === "internal") {
    return (
      <Link to={to} className={`action-button ${color} ${uniqueId}`}>
        <span className="mask">
          <span className="default" data-splitting="chars">
            {children}
          </span>
          <span className="secondary" data-splitting="chars">
            {children}
          </span>
        </span>
      </Link>
    );
  } else if (type === "external") {
    return (
      <button
        className={`action-button ${color} ${uniqueId}`}
        onClick={() => setBookingOpen(!bookingOpen)}
        role="button"
      >
        <span className="mask">
          <span className="default" data-splitting="chars">
            {children}
          </span>
          <span className="secondary" data-splitting="chars">
            {children}
          </span>
        </span>
        {bookingOpen
          ? createPortal(
              <FullScreenBooking
                setBookingOpen={setBookingOpen}
                id="8b177a55f9f5eebe"
              />,
              document.body
            )
          : " "}
      </button>
    );
  } else if (type === 'pdf'){
    return (
      <Link to={to} className={`action-button ${color} ${uniqueId}`}>
        <span className="mask">
          <span className="default" data-splitting="chars">
            {children}
          </span>
          <span className="secondary" data-splitting="chars">
            {children}
          </span>
        </span>
      </Link>
    )
  }
};

export default ActionButton;
