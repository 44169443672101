import gsap from "gsap";

let splitAllText = () => {
};
let splitTitleApparition = () => {
};
let splitBlockApparition = () => {
};

const init = (el) => {
  if (typeof window !== "undefined") {
    const Splitting = require("splitting");

    splitAllText = () => {
      let allSplittedTitles = document.querySelectorAll(
        "[data-splitted-title]"
      );
      let allSplittedBlocks = document.querySelectorAll(
        "[data-splitted-block]"
      );
      let allSplittedTitlesArray = [];
      let allSplittedBlocksArray = [];

      allSplittedTitles.forEach((splittedTitle) => {
        // Get dataset attributes
        const dataAttributes = splittedTitle.dataset;
        let splittedChars = Splitting({
          target: splittedTitle,
          by: "lines",
          key: "bi-",
        });

        /* gsap.fromTo(
            splittedChars[0].chars,
            { display: "inline-block", autoAlpha: 0, y: '80%' },
            {
              autoAlpha: 1,
              y: 0,
              display: "inline-block",
              stagger:  {
                amount: 0.6,
                from: "start"
              },
              delay: 0.5,
              duration: 0.8,
            }
          ); */
        allSplittedTitlesArray.push({
          id: dataAttributes.sTag ? dataAttributes.sTag : null,
          chars: splittedChars[0].lines,
        });
      });

      allSplittedBlocks.forEach((splittedBlock) => {
        // Get dataset attributes
        const dataAttributes = splittedBlock.dataset;
        let splittedLines = Splitting({
          target: splittedBlock,
          by: "lines",
          key: "bi-",
        });

        splittedLines[0].linesDOM = [];

        splittedLines[0].lines.forEach((line, index) => {
          // insert html <span> element before first html element of line
          line[0].insertAdjacentHTML(
            "beforebegin",
            `<span class="bi-line bi-line-${index}"></span>`
          );
          let lineWrapper = document.querySelector(`.bi-line-${index}`);
          line.forEach((char, index) => {
            lineWrapper.appendChild(char);
            char.insertAdjacentHTML(
              "afterend",
              `<span class="whitespace"> </span>`
            );
          });

          splittedLines[0].linesDOM.push(lineWrapper);
        });

        allSplittedBlocksArray.push({
          id: dataAttributes.sTag ? dataAttributes.sTag : null,
          lines: splittedLines[0],
        });
      });
      let splittedData = {
        splittedTitles: allSplittedTitlesArray,
        splittedBlocks: allSplittedBlocksArray,
      };

      return splittedData;
    };

    splitTitleApparition = (lines, timeline) => {
      let tl = timeline;
      if (!timeline) {
        tl = gsap.timeline({
          delay: 0.5,
          ease: "power1.inOut",
        });
      }
      lines.forEach((line, index) => {
        line.forEach((word, i) => {
          // split text in word to span
          let splittedWord = word.textContent.split("");
          word.textContent = "";

          // create span for each letter
          splittedWord.forEach((letter) => {
            let span = document.createElement("span");
            span.classList.add("char");
            span.textContent = letter;
            word.appendChild(span);
          });

          tl.fromTo(
            word.querySelectorAll(".char"),
            { display: "inline-block", autoAlpha: 0, y: "80%" },
            {
              autoAlpha: 1,
              y: 0,
              display: "inline-block",
              stagger: {
                amount: 0.3,
                from: "start",
              },
              duration: 0.6,
              ease: "power1.inOut",
            },
            index && i === 0
              ? "-=0.50"
              : i == 0
              ? index === 0
                ? 0
                : 1
              : index === 0
              ? 1
              : "<"
          );
        });
      });
    };

    splitBlockApparition = (block, delay, element, timeline) => {
      if (element === "block") {
        if (timeline) {
          timeline.fromTo(
            block,
            { autoAlpha: 0, y: "20px" },
            {
              autoAlpha: 1,
              y: 0,
              stagger: 0.05,
              duration: 0.6,
              ease: "linear",
            },
            delay
          );
        } else {
          gsap.fromTo(
            block,
            { autoAlpha: 0, y: "20px" },
            {
              autoAlpha: 1,
              y: 0,
              stagger: 0.05,
              duration: 0.6,
              ease: "linear",
            },
            delay
          );
        }
      } else {
        let tl = timeline;
        if (!timeline) {
          tl = gsap.timeline({
            delay,
          });
        }
        block.forEach((line, i) => {
          tl.fromTo(
            line,
            { y: "100%" },
            {
              y: 0,
              duration: 0.4,
              ease: "linear",
            },
            !timeline ? "-=0.35" : i === 0 ? delay : "-=0.35"
          );
        });
      }
    };
  }
};

if (typeof window !== "undefined") {
  const Splitting = require("splitting");

  splitAllText = () => {
    let allSplittedTitles = document.querySelectorAll(
      "[data-splitted-title]"
    );
    let allSplittedBlocks = document.querySelectorAll(
      "[data-splitted-block]"
    );
    let allSplittedTitlesArray = [];
    let allSplittedBlocksArray = [];

    allSplittedTitles.forEach((splittedTitle) => {
      // Get dataset attributes
      const dataAttributes = splittedTitle.dataset;
      let splittedChars = Splitting({
        target: splittedTitle,
        by: "lines",
        key: "bi-",
      });

      /* gsap.fromTo(
          splittedChars[0].chars,
          { display: "inline-block", autoAlpha: 0, y: '80%' },
          {
            autoAlpha: 1,
            y: 0,
            display: "inline-block",
            stagger:  {
              amount: 0.6,
              from: "start"
            },
            delay: 0.5,
            duration: 0.8,
          }
        ); */
      allSplittedTitlesArray.push({
        id: dataAttributes.sTag ? dataAttributes.sTag : null,
        chars: splittedChars[0].lines,
      });
    });

    allSplittedBlocks.forEach((splittedBlock, i) => {
      // Get dataset attributes
      const dataAttributes = splittedBlock.dataset;
      let target = document.querySelector(`[data-s-tag="${dataAttributes.sTag}"]`);
      let splittedLines = Splitting({
        target: target,
        by: "lines",
        key: "bi-",
      });

      splittedLines[0].linesDOM = [];

      splittedLines[0].lines.forEach((line, index) => {
        // insert html <span> element before first html element of line
        line[0].insertAdjacentHTML(
          "beforebegin",
          `<span class="bi-line bi-line-${index}"></span>`
        );
        let lineWrapper = document.querySelector(`[data-s-tag="${dataAttributes.sTag}"] .bi-line-${index}`);

        line.forEach((char, index) => {
          lineWrapper.appendChild(char);
          char.insertAdjacentHTML(
            "afterend",
            `<span class="whitespace"> </span>`
          );
        }); 

        splittedLines[0].linesDOM.push(lineWrapper);
      });

      allSplittedBlocksArray.push({
        id: dataAttributes.sTag ? dataAttributes.sTag : null,
        lines: splittedLines[0],
      });
    });
    let splittedData = {
      splittedTitles: allSplittedTitlesArray,
      splittedBlocks: allSplittedBlocksArray,
    };


    return splittedData;
  };

  splitTitleApparition = (lines, timeline) => {
    let tl = timeline;
    if (!timeline) {
      tl = gsap.timeline({
        delay: 0.5,
        ease: "power1.inOut",
      });
    }
    lines.forEach((line, index) => {
      line.forEach((word, i) => {
        // split text in word to span
        let splittedWord = word.textContent.split("");
        word.textContent = "";

        // create span for each letter
        splittedWord.forEach((letter) => {
          let span = document.createElement("span");
          span.classList.add("char");
          span.textContent = letter;
          word.appendChild(span);
        });

        tl.fromTo(
          word.querySelectorAll(".char"),
          { display: "inline-block", autoAlpha: 0, y: "80%" },
          {
            autoAlpha: 1,
            y: 0,
            display: "inline-block",
            stagger: {
              amount: 0.3,
              from: "start",
            },
            duration: 0.6,
            ease: "power1.inOut",
          },
          index && i === 0
            ? "-=0.50"
            : i == 0
            ? index === 0
              ? 0
              : 1
            : index === 0
            ? 1
            : "<"
        );
      });
    });
  };

  splitBlockApparition = (block, delay, element, timeline) => {
    if (element === "block") {
      if (timeline) {
        timeline.fromTo(
          block,
          { autoAlpha: 0, y: "20px" },
          {
            autoAlpha: 1,
            y: 0,
            stagger: 0.05,
            duration: 0.6,
            ease: "linear",
          },
          delay
        );
      } else {
        gsap.fromTo(
          block,
          { autoAlpha: 0, y: "20px" },
          {
            autoAlpha: 1,
            y: 0,
            stagger: 0.05,
            duration: 0.6,
            ease: "linear",
          },
          delay
        );
      }
    } else {
      let tl = timeline;
      if (!timeline) {
        tl = gsap.timeline({
          delay,
        });
      }
      block.forEach((line, i) => {
        tl.fromTo(
          line,
          { y: "100%" },
          {
            y: 0,
            duration: 0.4,
            ease: "linear",
          },
          !timeline ? "-=0.35" : i === 0 ? delay : "-=0.35"
        );
      });
    }
  };
}

export default {
  init,
  splitAllText,
  splitTitleApparition,
  splitBlockApparition,
};
