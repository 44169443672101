import React from "react";
import { Link } from "gatsby";

const InformativeList = ({ lang }) => {
  return (
    <>
      <div>
        <h3>Contact</h3>
        <ul className="listLink contacts">
          <li>
            <a href="mailto:contact@ilebarbe.com" target="_blank">contact@ilebarbe.com</a>
          </li>
          <li>
            <a href="tel:+33469963833" target="_blank">+33 (0)4 69 96 38 33</a>
          </li>
          <li>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/lessuitesdelilebarbe"
                  target="_blank"
                >
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="15.5001"
                      cy="15.5001"
                      r="15.0001"
                      fill="#D7D7D7"
                      className="circle"
                    />
                    <circle
                      cx="15.5001"
                      cy="15.5001"
                      r="15.0001"
                      stroke="#D7D7D7"
                    />
                    <path
                      d="M20.6668 9.18555H10.3334C9.69904 9.18555 9.18524 9.69935 9.18524 10.3337V20.6671C9.18524 21.3015 9.69904 21.8153 10.3334 21.8153H16.0742V16.6486H14.3519V14.9263H16.0742V14.0015C16.0742 12.2505 16.9273 11.4819 18.3825 11.4819C19.0795 11.4819 19.448 11.5335 19.6226 11.5571V13.2041H18.63C18.0123 13.2041 17.7964 13.5302 17.7964 14.1904V14.9263H19.6071L19.3613 16.6486H17.7964V21.8153H20.6668C21.3012 21.8153 21.815 21.3015 21.815 20.6671V10.3337C21.815 9.69935 21.3006 9.18555 20.6668 9.18555Z"
                      fill="#F8F4F2"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/lessuitesdelilebarbe/"
                  target="_blank"
                >
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="15.5001"
                      cy="15.5001"
                      r="15.0001"
                      fill="#D7D7D7"
                      className="circle"
                    />
                    <circle
                      cx="15.5001"
                      cy="15.5001"
                      r="15.0001"
                      stroke="#D7D7D7"
                    />
                    <path
                      d="M20.8005 10.1333H11.2005C10.6111 10.1333 10.1338 10.6106 10.1338 11.2V20.8C10.1338 21.3893 10.6111 21.8666 11.2005 21.8666H20.8005C21.3898 21.8666 21.8671 21.3893 21.8671 20.8V11.2C21.8671 10.6106 21.3898 10.1333 20.8005 10.1333ZM13.8426 19.7333H12.2693V14.6709H13.8426V19.7333ZM13.0399 13.9472C12.5327 13.9472 12.1226 13.536 12.1226 13.0298C12.1226 12.5237 12.5333 12.113 13.0399 12.113C13.5455 12.113 13.9567 12.5242 13.9567 13.0298C13.9567 13.536 13.5455 13.9472 13.0399 13.9472ZM19.7359 19.7333H18.1637V17.2714C18.1637 16.6842 18.153 15.929 17.3461 15.929C16.5274 15.929 16.4015 16.5685 16.4015 17.2288V19.7333H14.8293V14.6709H16.3386V15.3626H16.3599C16.5701 14.9648 17.0831 14.545 17.8485 14.545C19.4415 14.545 19.7359 15.5936 19.7359 16.9568V19.7333V19.7333Z"
                      fill="#F8F4F2"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/lessuitesdelilebarbe/"
                  target="_blank"
                >
                  <svg
                    width="32"
                    height="31"
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="15.9725"
                      cy="15.5001"
                      r="15.0001"
                      fill="#D7D7D7"
                      className="circle"
                    />

                    <circle
                      cx="15.9725"
                      cy="15.5001"
                      r="15.0001"
                      stroke="#D7D7D7"
                    />
                    <path
                      d="M13.1021 9.04199C11.1237 9.04199 9.5141 10.6516 9.5141 12.63V18.3708C9.5141 20.3492 11.1237 21.9587 13.1021 21.9587H18.8429C20.8213 21.9587 22.4309 20.3492 22.4309 18.3708V12.63C22.4309 10.6516 20.8213 9.04199 18.8429 9.04199H13.1021ZM13.1021 10.4772H18.8429C20.0298 10.4772 20.9957 11.4431 20.9957 12.63V18.3708C20.9957 19.5577 20.0298 20.5235 18.8429 20.5235H13.1021C11.9152 20.5235 10.9493 19.5577 10.9493 18.3708V12.63C10.9493 11.4431 11.9152 10.4772 13.1021 10.4772ZM19.5605 11.1948C19.3701 11.1948 19.1876 11.2704 19.053 11.405C18.9185 11.5395 18.8429 11.7221 18.8429 11.9124C18.8429 12.1027 18.9185 12.2852 19.053 12.4198C19.1876 12.5544 19.3701 12.63 19.5605 12.63C19.7508 12.63 19.9333 12.5544 20.0679 12.4198C20.2025 12.2852 20.2781 12.1027 20.2781 11.9124C20.2781 11.7221 20.2025 11.5395 20.0679 11.405C19.9333 11.2704 19.7508 11.1948 19.5605 11.1948ZM15.9725 11.9124C13.9941 11.9124 12.3845 13.522 12.3845 15.5004C12.3845 17.4788 13.9941 19.0884 15.9725 19.0884C17.9509 19.0884 19.5605 17.4788 19.5605 15.5004C19.5605 13.522 17.9509 11.9124 15.9725 11.9124ZM15.9725 13.3476C17.1594 13.3476 18.1253 14.3135 18.1253 15.5004C18.1253 16.6873 17.1594 17.6532 15.9725 17.6532C14.7856 17.6532 13.8197 16.6873 13.8197 15.5004C13.8197 14.3135 14.7856 13.3476 15.9725 13.3476Z"
                      fill="#F8F4F2"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div>
        <h3> {lang === "fr" ? "Adresse" : "Address"}</h3>
        <p>9 impasse Saint Loup Île Barbe 69009 Lyon, France</p>
      </div>
    </>
  );
};

export default InformativeList;
