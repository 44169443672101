import React, { useState, createContext, useEffect } from "react";
import { SwitchTransition, Transition } from "react-transition-group";

import ScrollTriggerInit from "../utils/scrollTrigger";

import imagesloaded from "imagesloaded";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/src/all";
import eventBus from "../../src/utils/eventBus";
import Header from "./blocks/Header.jsx";
import Footer from "./blocks/Footer.jsx";
import Menu from "./blocks/Menu.jsx";

import "../styles/utils/root.scss";

gsap.registerPlugin(ScrollTrigger);

export const LayoutContext = createContext({
  menuOpen: false,
  setMenuOpen: () => {},
  gsap: null,
  scroll: null,
});

const MenuLayout = (path, { scroll }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  let path2 = path.path;
  useEffect(() => {
    eventBus.on("pageAppear", () => {
      setMenuOpen(false);
    });
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.querySelector("body").style.overflow = "hidden";
      eventBus.dispatch("stopScroll");
    } else {
      document.querySelector("body").style.overflow = "hidden auto";
      eventBus.dispatch("enableScroll");
    }
  }, [menuOpen]);

  return (
    <>
      <LayoutContext.Provider value={{ menuOpen, setMenuOpen }}>
        <Header path={path2} menuOpen={menuOpen} />
        {menuOpen ? <Menu path={path2}></Menu> : null}
      </LayoutContext.Provider>
    </>
  );
};

const Layout = (props) => {
  const [asscroll, setAsscroll] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const scrollLock = require("../utils/scroll-lock.js").default;
    console.log(scrollLock, "scroll Lock");
    imagesloaded("body", () => {
      setLoaded(true);
      const ASScroll = require("@ashthornton/asscroll");
      let asscroll1 = new ASScroll({
        disableRaf: true,
      });
      asscroll1.enable();
      ScrollTriggerInit(ScrollTrigger, asscroll1, gsap);
      setAsscroll(asscroll1);
      gsap.set("body", { autoAlpha: 1 });
      gsap.fromTo(
        "header",
        { autoAlpha: 0, y: "-10px" },
        { autoAlpha: 1, y: 0, duration: 1 }
      );

      eventBus.on("ExitTransition", (e) => {
        asscroll1.scrollTo(0);
      });

      eventBus.on("stopScroll", () => {
        asscroll1.disable();
        scrollLock.enable();
      });

      eventBus.on("enableScroll", () => {
        scrollLock.disable();
        asscroll1.enable();
      });
    });
  }, []);

  return (
    <div>
      <div asscroll-container="true">
        <div>
          <MenuLayout path={props.path} scroll={asscroll} />
          <div className="transition_wrapper">
            <SwitchTransition mode="out-in">
              <Transition
                key={Math.random()}
                appear={false}
                in={true}
                timeout={500}
                onExit={(node) => {
                  if (loaded) {
                    gsap.fromTo(
                      node,
                      { opacity: 1, y: 0, zIndex: 2 },
                      {
                        opacity: 0,
                        zIndex: 2,
                        duration: 0.5,
                        onComplete: () => {
                          eventBus.dispatch("ExitTransition");
                        },
                      }
                    );
                  }
                }}
                onEnter={(node) => {
                  eventBus.dispatch("EnterTransition");
                  gsap.fromTo(
                    node,
                    { opacity: 0, y: 5, zIndex: 5 },
                    {
                      opacity: 1,
                      y: 0,
                      zIndex: 5,
                      duration: 1,
                      delay: 0.5,
                      onComplete: () => {
                        //remove tranform in style attribute od node
                        node.style.transform = "";
                      },
                    }
                  );
                }}
              >
                <div
                  className={
                    props.path === "/a_propos" ||
                    props.path === "/a_propos/" ||
                    props.path === "/en/a_propos" ||
                    props.path === "/en/a_propos/"
                      ? "bi_content about"
                      : "bi_content"
                  }
                >
                  <>
                    <LayoutContext.Provider value={{ gsap, scroll: asscroll }}>
                      <LayoutContext.Consumer>
                        {(context) => {
                          return (
                            <>
                              {React.cloneElement(props.children, { context })}
                              <Footer path={props.path} context={context} />
                            </>
                          );
                        }}
                      </LayoutContext.Consumer>
                    </LayoutContext.Provider>
                  </>
                </div>
              </Transition>
            </SwitchTransition>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
